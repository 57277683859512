<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4 mb-3">
        <div class="row" v-if="!isLoad">
            <div class="col-12" v-if="questions.length">
                <div class="card p-0 mb-3" v-for="(item, index) in questions" :key="index">
                    <div class="card-title border-bottom bg-grey px-3 py-2 mb-0">
                        <a class="float-right font-xs" @click="deleteAlert(item.id)" href="javascript:void(0)"><i class="fas fa-trash text-danger"></i></a>
                        <a class="float-right font-xs mr-3" @click="showModal(item)" href="javascript:void(0)"><i class="fas fa-edit text-warning"></i></a>
                    </div>
                    <div class="card-body text-center" v-if="item.quest_type == 1">
                        <video class="w-50 h-auto" controls>
                            <source :src=" item.path" type="video/mp4">
                        </video>
                    </div>
                    <div class="card-body" v-if="item.quest_type == 2">
                        <audio class="w-100" controls>
                            <source :src=" item.path" type="audio/mpeg">
                        </audio>
                    </div>
                    <div class="card-body ql-editor">
                        <div v-html="item.question"></div>
                    </div>
                    <div v-if="item.submission_type == 1" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                        Multiple Choice
                    </div>
                    <div v-if="item.submission_type == 2" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                        Multiple Answer
                    </div>
                    <div style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                        <table class="table m-0">
                            <tr v-for="(opt, j) in item.option" :key="j">
                                <td width="5%" v-if="opt.is_answer" class="bg-success"></td>
                                <td width="5%" v-else class="bg-grey"></td>
                                <td>{{opt.option}}</td>
                                <td>Category : <b>{{opt.category}}</b></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center" v-else>
                <img :src="'/images/empty.png'" alt="" width="300">
                <h1 class="text-muted">Data don't exist</h1>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-12 text-center py-3">
                <div class="my-3">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
            </div>
        </div>
        <modal name="edit-modal"
            :width="'80%'"
            :height="'auto'"
            :scrollable="true"
            @before-close="beforeClose">
            <div class="container-fluid">
                <div class="row mb-0 mt-2 py-2">
                    <div class="col-12 px-4 py-0">
                        <a @click="closeModal()" href="javascript:void(0)" class="float-right"><i class="fas fa-times text-danger"></i></a>
                    </div>
                </div>
                <div class="row align-item-center justify-content-center pb-3">
                    <div class="col-12">
                        <edit @update-data="closeModal()" :data="current"></edit>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import 'vue2-editor/dist/vue2-editor.css'
import axios from 'axios'
import Edit from './Edit.vue'
export default {
    props: ['newData'], 
    components: {
        Edit
    },
    watch:{
        newData: function(newVal){
            if(newVal) {
                this.getQuestions();
            }
        }
    },
    data(){
        return{
            id : this.$route.params.idVark,
            media : process.env.VUE_APP_URL_CLOUD,
            isLoad : true,
            current : null,
            questions: []
        }
    },
    created(){
        this.getQuestions()
    },
    methods:{
        showModal(item){
            if(item) {
                this.current = item
                this.$modal.show('edit-modal')
            }
        },
        closeModal(){
            this.$modal.hide('edit-modal')
        },
        beforeClose() {
            this.current = null
            this.getQuestions()
        },
        async getQuestions(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/vark/quest?id=${this.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.questions = res.data.data
                this.isLoad = false
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        _method: 'delete',
                        vark_id: this.id,
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/vark/quest`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Question!',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getQuestions()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Question!',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    }
}
</script>